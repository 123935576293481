import { gql } from '@apollo/client'
import { HighestBids } from '../../fragments/highestBids'

export const getMyHighestBidByCarId = gql`
  ${HighestBids}
  query GetMyHighestBidByCarId($carId: String!) {
    getMyHighestBidByCarId(carId: $carId) {
      ...HighestBids
    }
  }
`
