import { createContext, FC } from 'react'
import useWebSocket from 'react-use-websocket'
import { getSoketNotificationByCode } from '../data/notification/socket'
import { getAccessToken } from '../utils/auth'
import { useNotificationContext } from './hooks'
import { SocketContextData, SocketProviderProps } from './types'

const initialContextData: SocketContextData = {
  socket: undefined,
}

export const SocketContext = createContext<SocketContextData>(initialContextData)

export const SocketProvider: FC<SocketProviderProps> = ({ children, url }) => {
  const token = getAccessToken()
  const { setNotificationItems } = useNotificationContext()

  const connectionUrl = `${url}?token=${token}`

  const socket = useWebSocket(connectionUrl, {
    onOpen: () => console.log('Connected to the server'),
    onMessage: event => {
      const { code } = JSON.parse(event.data)
      const notification = getSoketNotificationByCode(code)

      notification && setNotificationItems([notification])
    },
    onClose: () => console.log('Disconnected from the server'),
    onError: error => console.error('WebSocket error:', error),
    shouldReconnect: () => true, // Abilita la riconnessione automatica
    reconnectAttempts: 10, // Numero massimo di tentativi di riconnessione
    reconnectInterval: 2000, // Intervallo tra i tentativi di riconnessione (in millisecondi)
  })

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
}
