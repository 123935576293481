import { Box } from '@mui/material'
import styled from 'styled-components'

export const Wrapper = styled.div<{}>`
  width: 100vw;
  overscroll-behavior: none;
`

export const InnerWrapper = styled(Box)<{ disablePadding: boolean }>`
  overflow: auto;
  padding: ${({ disablePadding }) =>
    disablePadding ? '0' : '0 clamp(36px, 3%, 120px) 0 clamp(36px, 3%, 120px)'};
`
