import { gql } from '@apollo/client'
import { OrderPaginated } from '../../fragments/orderPaginated'

export const getMyOrders = gql`
  ${OrderPaginated}
  query GetMyOrders($pagination: PaginationInput) {
    getMyOrders(pagination: $pagination) {
      ...OrderPaginated
    }
  }
`
