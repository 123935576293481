import BaseWrapper from '@components/BaseWrapper'
import { PrimaryButton } from '@core/components/Button'
import useFormBuilder from '@core/components/Form'
import FormContainer from '@core/components/Form/FormContainer'
import { FormCompleteHandler, FormError } from '@core/components/Form/FormContainer/type'
import { Body, Headline } from '@core/components/Typography'
import { useGraphqlLazy } from '@core/hooks/queries/graphqlHooks'
import { useActions } from '@core/hooks/useActions'
import { LinkBackTo, SignInLayoutPage } from '@core/pages/private/signIn'
import { useTranslation } from '@core/providers/hooks/i18n'
import { updateAccount } from '@core/services/state/user'
import { SignInWrapper } from '@privatePages/signin/login/style'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LicenseFormType } from './types'

const LicensePage: FC = () => {
  const navigate = useNavigate()
  const actions = useActions({ updateAccount })
  const { t } = useTranslation()

  const [validateNewLicenseCb] = useGraphqlLazy<{
    validateNewLicense: string
  }>('validateNewLicense')

  // useErrorManager('licenseError', [apolloError])

  const [error] = useState<FormError<LicenseFormType>>()
  const { InputText } = useFormBuilder<LicenseFormType>()

  const onSubmit: FormCompleteHandler<LicenseFormType> = async formData => {
    const { data } = await validateNewLicenseCb({
      variables: {
        licenseId: formData.token,
      },
    })
    if (data?.validateNewLicense) {
      const licenseId = data.validateNewLicense
      if (licenseId) {
        actions.updateAccount({ license: licenseId })
        navigate('/register')
      }
    }
    // return await readLicenseReq(
    //   { license: data.token },
    //   {
    //     onSuccess: lic => {
    //       if (lic.active && !lic.used) {
    //         navigate('/register')
    //       }
    //     },
    //     onError: e => {
    //       setError({ fieldName: 'token', message: e })
    //       // if (!license.active && license?.used) {
    //       //     // TODO: return custom message that says that the token is not active (used or expired)
    //       //     // return setInvalidTokenMessage()
    //       //   }
    //     },
    //   }
    // )
  }

  return (
    <BaseWrapper disablePadding hideSidebar hideToolbar headerComponent={<></>}>
      <SignInWrapper>
        <SignInLayoutPage>
          <Headline textTransform={'capitalize'} color="primaryLight">
            {t('core.welcome')}
          </Headline>
          <Body color="primaryLight">{t('register.tokenDescription')}</Body>
          <FormContainer<LicenseFormType> onSubmit={onSubmit} error={error} id="license">
            <InputText label={t('register.token')} fieldName="token" required />
            <PrimaryButton type="submit" color="secondary">
              {t('register.checkTokenValidity')}
            </PrimaryButton>
          </FormContainer>
          <LinkBackTo label={t('login.backToLoginPage')} to="/login" />
        </SignInLayoutPage>
      </SignInWrapper>
    </BaseWrapper>
  )
}

export default LicensePage
