import { useMemo } from 'react'
import { FieldValues } from 'react-hook-form'

import {
  Chip,
  ChipProps,
  ColorPicker,
  ColorPickerProps,
  ImageUploader,
  ImageUploaderProps,
  InputDate,
  InputDateProps,
  InputPhone,
  InputText,
  InputTextProps,
  Slider,
  SliderProps,
  StepperSelector,
  StepperSelectorProps,
} from '../Form/FormWidgets'
import Checkbox from '../Form/FormWidgets/Checkbox'
import { CheckboxProps } from '../Form/FormWidgets/Checkbox/types'
import Radio from '../Form/FormWidgets/Radio'
import { RadioProps } from '../Form/FormWidgets/Radio/types'
import Select from '../Form/FormWidgets/Select'
import { SelectProps } from '../Form/FormWidgets/Select/types'
import Switch from '../Form/FormWidgets/Switch'
import { SwitchProps } from '../Form/FormWidgets/Switch/types'
import HiddenField from './FormWidgets/HiddenField'
import { HiddenFieldProps } from './FormWidgets/HiddenField/types'
import { InputPhoneProps } from './FormWidgets/InputPhone/types'

const useFormBuilder = <T extends FieldValues>() => {
  return useMemo(
    () => ({
      InputText: (props: InputTextProps<T>) => InputText<T>(props),
      InputPhone: (props: InputPhoneProps<T>) => InputPhone<T>(props),
      InputDate: (props: InputDateProps<T>) => InputDate<T>(props),
      Select: (props: SelectProps<T>) => Select<T>(props),
      Switch: (props: SwitchProps<T>) => Switch<T>(props),
      Checkbox: (props: CheckboxProps<T>) => Checkbox<T>(props),
      Radio: (props: RadioProps<T>) => Radio<T>(props),
      ColorPicker: (props: ColorPickerProps<T>) => ColorPicker<T>(props),
      Chip: (props: ChipProps<T>) => Chip<T>(props),
      ImageUploader: (props: ImageUploaderProps<T>) => ImageUploader<T>(props),
      Slider: (props: SliderProps<T>) => Slider<T>(props),
      StepperSelector: (props: StepperSelectorProps<T>) => StepperSelector<T>(props),
      HiddenField: (props: HiddenFieldProps<T>) => HiddenField<T>(props),
    }),
    []
  )
}

export default useFormBuilder
