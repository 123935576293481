import { ApolloProvider } from '@apollo/client'
import config from '@config'
import Loading from '@core/components/Loading'
import { NavigationProvider, NotificationProvider } from '@core/providers'
import TranslationProvider from '@core/providers/translation'
import { GlobalStyle } from '@core/styles'
import { materialTheme } from '@core/styles/theme'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { store } from '@state/store'
import { getClient } from 'core/apolloClient'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from 'src/App'
import { ThemeProvider } from 'styled-components'

const AppTree = () => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={materialTheme}>
        <ThemeProvider theme={materialTheme}>
          <Provider store={store}>
            <ApolloProvider client={getClient(config.bff.url)}>
              <TranslationProvider collectionType="dashboard">
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <GlobalStyle />
                  <NotificationProvider>
                    <NavigationProvider mediaSupportedEnabled={config.features.mediaSupported}>
                      <Suspense fallback={<Loading />}>
                        <App />
                      </Suspense>
                    </NavigationProvider>
                  </NotificationProvider>
                </LocalizationProvider>
              </TranslationProvider>
            </ApolloProvider>
          </Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}

let container: HTMLElement | null = null

document.addEventListener('DOMContentLoaded', () => {
  if (!container) {
    container = document.getElementById('root') as HTMLElement
    const root = ReactDOM.createRoot(container)
    root.render(
      <React.StrictMode>
        <AppTree />
      </React.StrictMode>
    )
  }
})
