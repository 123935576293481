import styled from 'styled-components'

export const ImageWrapper = styled.img<{ width: string }>`
  border-radius: 20px;
  height: ${({ width }) => width};
  aspect-ratio: 1/1;
  background: ${({ theme }) => theme.palette.secondary.main};
  padding: 5px;
  cursor: pointer;
`
