import Tabs from '@components/Tabs'
import { TabsListInterface } from '@components/Tabs/types'
import { ConditionalRender } from '@core/components'
import { UserImage } from '@core/components/UserImage'
import useUserLoginHandler from '@core/hooks/useUserLoginStatus'
import { useThemeContext } from '@core/providers/hooks'
import { usePageChecker } from '@hooks/usePageChecker'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import SettingsIcon from '@mui/icons-material/Settings'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { NavLink, useNavigate } from 'react-router-dom'
import { ToolbarFirstLevel, ToolbarWrapper } from './style'

const tabsMapping: TabsListInterface = {
  isHomePage: [
    { path: 'home.overview', available: true },
    { path: 'home.report', available: false },
    { path: 'home.statistics', available: false },
  ],
  isSellPage: [
    { path: 'sell.add', available: true },
    { path: 'sell.cars-list', available: true },
    { path: 'sell.statistics', available: false },
    { path: 'sell.archive', available: false },
  ],
  isBuyPage: [
    { path: 'buy.overview', available: true },
    { path: 'buy.search', available: true },
    { path: 'buy.garage', available: true },
  ],
  isAdminPage: [],
  isObjectivesPage: [
    { path: 'objectives.overview', available: false },
    { path: 'objectives.report', available: false },
    { path: 'objectives.statistics', available: false },
  ],
}

const ToolBar = () => {
  const { currentPage } = usePageChecker()
  const theme = useThemeContext()
  const navigate = useNavigate()
  const { isAdmin } = useUserLoginHandler()
  if (isAdmin) {
    tabsMapping['isAdminPage'] = [
      { path: 'admin.tokens', available: true },
      { path: 'admin.users', available: true },
      { path: 'admin.cars', available: true },
      { path: 'admin.orders', available: true },
    ]
  }
  const tabsList = currentPage ? tabsMapping[currentPage] : []

  return (
    <ToolbarWrapper>
      <ToolbarFirstLevel>
        <SettingsIcon sx={{ fontSize: '24px', fill: theme.palette.grey[300] }} />
        <NotificationsActiveIcon sx={{ fontSize: '24px', fill: theme.palette.grey[300] }} />
        <NavLink to={'/buy/garage#orders'}>
          <ShoppingCartIcon sx={{ fontSize: '24px' }} />
        </NavLink>
        <div onClick={() => navigate('/user')}>
          <UserImage />
        </div>
      </ToolbarFirstLevel>
      <ConditionalRender condition={tabsList.length} render={() => <Tabs tabsList={tabsList} />} />
    </ToolbarWrapper>
  )
}

export default ToolBar
