import { NotificationItem, NotificationSocketCode } from '../../providers/types'

const likedMyCarNotification: NotificationItem = {
  code: 'likedMyCar',
  status: {
    severity: 'info',
    label: 'likedMyCar',
  },
}

const newBidBiddedMyCarNotification: NotificationItem = {
  code: 'newBid_biddedMyCar',
  status: {
    severity: 'info',
    label: 'newBid_biddedMyCar',
  },
}

const newBidNewHighestEndedNotification: NotificationItem = {
  code: 'newBid_newHighest',
  status: {
    severity: 'info',
    label: 'newBid_newHighest',
  },
}

const auctionEndedNotification: NotificationItem = {
  code: 'auction_ended',
  status: {
    severity: 'info',
    label: 'auctionEnded',
  },
}
const newOrderNotification: NotificationItem = {
  code: 'newOrder_soldMyCar',
  status: {
    severity: 'info',
    label: 'newOrder_soldMyCar',
  },
}

export const getSoketNotificationByCode = (code: NotificationSocketCode) => {
  const notifications: Partial<Record<NotificationSocketCode, NotificationItem>> = {
    likedMyCar: likedMyCarNotification,
    newBid_biddedMyCar: newBidBiddedMyCarNotification,
    newBid_newHighest: newBidNewHighestEndedNotification,
    newOrder_soldMyCar: newOrderNotification,
    auction_ended: auctionEndedNotification,
  }

  return notifications && notifications[code]
}
