import { LinkBackTo } from 'core/src/pages/private/signIn'
import { FC, useState } from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import { ConditionalRender } from '../../../../components'
import { PrimaryButton } from '../../../../components/Button'
import { InputText } from '../../../../components/Form/FormWidgets'
import { useFormContext } from '../../../../components/Form/hooks/useFormContext'
import { PasswordChecklistStyled } from '../../../../components/RegisterForm'
import { useTranslation } from '../../../../providers/hooks/i18n'

interface ForgotPasswordFormProps {
  className?: string
  disabledEmail?: boolean
}

export type UpdatePasswordFormType = {
  email: string
  password: string
  verificationCode: string
}

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({ disabledEmail = true }) => {
  const { t } = useTranslation()
  const [passwordFocused, setPasswordFocused] = useState<boolean>(false)
  const { watch } = useFormContext()
  const password = watch('password', '')
  return (
    <>
      {t('')}
      <InputText label={t('form.email')} fieldName="email" required disabled={disabledEmail} />
      <InputText label={t('form.verificationCode')} fieldName="verificationCode" required />
      <InputText
        minLength={8}
        onFocus={() => setPasswordFocused(true)}
        label={t('form.password')}
        fieldName="password"
        type="password"
        required
      />
      {/* TODO: form builder - verificare che minLength e maxLength stiano funzionando + propagare pattern
      pattern: { 
  value: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~]).*$/,
  message: t('signup.password.incorrect'), */}

      <ConditionalRender
        condition={passwordFocused}
        render={() => (
          <>
            <PasswordStrengthBar
              shortScoreWord={t('alerts.info.password.validateShort')}
              scoreWords={[
                t('alerts.info.password.validateWeak'),
                t('alerts.info.password.validateOkay'),
                t('alerts.info.password.validateGood'),
                t('alerts.info.password.validateStrong'),
                t('alerts.info.password.validateStronger'),
              ]}
              password={password}
            />
            <PasswordChecklistStyled
              rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
              minLength={8}
              iconSize={13}
              value={password}
              messages={{
                minLength: t('alerts.errors.password.minLength'),
                specialChar: t('alerts.errors.password.specialChar'),
                number: t('alerts.errors.password.noNumber'),
                capital: t('alerts.errors.password.capitalLetter'),
                lowercase: t('alerts.errors.password.lowercaseLetter'),
              }}
            />
          </>
        )}
      />
      <PrimaryButton type="submit" color="secondary" fullWidth={false}>
        {t('core.login')}
      </PrimaryButton>

      <LinkBackTo label={t('login.backToLoginPage')} to="/login" />
    </>
  )
}
