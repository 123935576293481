export const colorsValueHexMap: Record<string, string> = {
  '0': '#ff7500',
  '1': '#c4c4c4',
  '2': '#dbaf6e',
  '3': '#FFFFFF',
  '4': '#0059b2',
  '5': '#DB9D5D',
  '6': '#f7c81e',
  '7': '#949494',
  '8': '#991289',
  '9': '#994200',
  '10': '#000000',
  '11': '#e8c84a',
  '12': '#d91a2a',
  '13': '#38a614',
}
