import { Box } from '@mui/material'
import styled from 'styled-components'
import { SecondaryButton } from '../../../Button'

export const StepperSelectorWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`
export const SecondaryButtonStyled = styled(SecondaryButton)`
  padding: 9px;
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`
