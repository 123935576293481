import BaseWrapper from '@components/BaseWrapper'
import FormContainer from '@core/components/Form/FormContainer'
import { FormCompleteHandler } from '@core/components/Form/FormContainer/type'
import { useGraphqlMutation } from '@core/hooks/queries/graphqlHooks'
import { useFullscreenLoader } from '@core/hooks/useFullscreenLoader'
import { SignInLayoutPage } from '@core/pages/private/signIn'
import { ForgotPasswordForm, UpdatePasswordFormType } from '@core/pages/private/signIn/forgotPasswordForm'
import { AstautoReduxStore } from '@customTypes/AstautoReduxStore'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const ForgotPasswordMessagePage = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const email = useSelector((s: AstautoReduxStore) => s.user.account.email)

  useFullscreenLoader('updatePassword', [loading])
  const [updateUserPasswordCb] = useGraphqlMutation('updateUserPassword')

  const onSubmit: FormCompleteHandler<UpdatePasswordFormType> = async formData => {
    setLoading(true)
    const { password, verificationCode } = formData
    await updateUserPasswordCb({
      variables: { email, verificationCode, newPassword: password },
    })

    navigate('/login')
  }
  return (
    <BaseWrapper disablePadding hideSidebar hideToolbar headerComponent={<></>}>
      <SignInLayoutPage>
        <FormContainer<UpdatePasswordFormType> onSubmit={onSubmit} initData={{ email }} id="updatePassword">
          <ForgotPasswordForm disabledEmail={!!email} />
        </FormContainer>
      </SignInLayoutPage>
    </BaseWrapper>
  )
}
