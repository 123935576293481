import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const SidebarWrapper = styled.div`
  width: 20%;
  min-width: 250px;
  z-index: 15;
  height: 100vh;
  background: linear-gradient(180deg, #0b1728 0%, #141414 100%);
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 0 20px 20px 0;
  overflow: hidden;
  padding: 35px 0;
  text-align: center;
`

export const Menu = styled.ul`
  text-align: left;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
`

export const MenuItem = styled(NavLink)`
  min-height: 50px;
  padding-left: clamp(10px, 18%, 60px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 10px;
  color: white;

  &.disabled {
    opacity: 0.6;
  }

  &.active::before {
    content: '';
    width: 5px;
    background: white;
    position: absolute;
    height: 100%;
    left: 0;
  }
`
