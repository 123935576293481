import { TextField } from '@mui/material'
import { get } from 'lodash'
import { Controller, FieldValues } from 'react-hook-form'
import { useFormContext } from '../../hooks/useFormContext'
import { useGetInputProps } from '../../hooks/useGetInputProps'
import { FormFieldValue } from '../types'
import { SelectProps } from './types'

const Select = <T extends FieldValues>({
  fieldName,
  required,
  type,
  label,
  helperText,
  options,
  defaultValue = '',
  disabled,
}: SelectProps<T>) => {
  const inputProps = useGetInputProps({ required, helperText })

  const {
    control,
    formState: { errors },
  } = useFormContext<T>()
  const registerObj = { required, valueAsNumber: type === 'number' }

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={registerObj}
      defaultValue={defaultValue as FormFieldValue<T>}
      render={({ field: { value, onChange } }) => (
        <TextField
          {...inputProps}
          disabled={disabled}
          select
          error={!!get(errors, fieldName)}
          onChange={event => {
            let value: string | number = event.target.value
            if (type === 'number') {
              value = parseInt(value)
            }
            onChange(value)
          }}
          label={label}
          helperText={helperText || ' '}
          id={fieldName}
          SelectProps={{ native: true }}
          value={value}
        >
          <option
            key={`${fieldName}-undefined`}
            selected
            value={undefined}
            style={{ display: 'none' }}
          ></option>
          {options.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </TextField>
      )}
    />
  )
}

export default Select
