import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { User, UserAccount, UserProfile } from '../../../types/graphqlTypes'

const initialUserAccountState: UserAccount = {
  email: '',
  isVerified: false,
  license: '',
  accessToken: '',
  authorizations: {},
}

const initialUserProfileState: UserProfile = {
  name: '',
  phone: '',
  phoneCountryCode: '',
  region: '',
  surname: '',
}

const initialState: User = {
  uuid: '',
  createdAt: '',
  updatedAt: '',
  account: initialUserAccountState,
  profile: initialUserProfileState,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (_, action: PayloadAction<Partial<User>>) => {
      return action.payload as User
    },
    logoutUser: () => initialState,
    updateAccount: (state: User, action: PayloadAction<Partial<UserAccount>>) => {
      return {
        ...state,
        account: {
          ...state.account,
          ...action.payload,
        },
      }
    },
    updateProfile: (state: User, action: PayloadAction<Partial<UserProfile>>) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }
    },
    setForgotPasswordMail: (state: User, action: PayloadAction<string>) => {
      return {
        ...state,
        account: {
          ...state.account,
          email: action.payload,
        },
      }
    },
  },
})

export const userSliceReducer = userSlice.reducer
export const { setUser, updateProfile, updateAccount, logoutUser, setForgotPasswordMail } = userSlice.actions
