import { FC, useState } from 'react'
import PasswordChecklist from 'react-password-checklist'
import PasswordStrengthBar from 'react-password-strength-bar'
import styled from 'styled-components'
import { ConditionalRender } from '..'
import { SignInFormButtonContainer } from '../../pages/private/signIn/style'
import { useTranslation } from '../../providers/hooks/i18n'
import { PrimaryButton, SecondaryButton } from '../Button'
import Divider from '../Divider'
import useFormBuilder from '../Form'
import FormContainer from '../Form/FormContainer'
import { FormCompleteHandler } from '../Form/FormContainer/type'

import { RegisterLayoutPage } from '../../pages/private/signIn'
import { useFormContext } from '../Form/hooks/useFormContext'
import { Body, Headline } from '../Typography'

export type RegisterType = {
  email: string
  password: string
}

interface RegisterFormProps {
  onSubmit: FormCompleteHandler<RegisterType>
  showTitle?: boolean
  showTokenText?: boolean
}

export const PasswordChecklistStyled = styled(PasswordChecklist)`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  svg {
    margin-top: 6px;
  }
`

export const RegisterFormContent = () => {
  const [passwordFocused, setPasswordFocused] = useState<boolean>(false)
  const { t } = useTranslation()
  const { InputText } = useFormBuilder<RegisterType>()

  const { watch } = useFormContext()
  const password = watch('password', '')

  return (
    <>
      <InputText label={t('form.email')} fieldName="email" type="email" required />
      {/* TODO: create from builder Password component */}
      <InputText
        minLength={8}
        onFocus={() => setPasswordFocused(true)}
        label={t('form.password')}
        fieldName="password"
        type="password"
        required
      />
      {/* TODO: form builder - verificare che minLength e maxLength stiano funzionando + propagare pattern
      pattern: { 
  value: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~]).*$/,
  message: t('signup.password.incorrect'), */}

      <ConditionalRender
        condition={passwordFocused}
        render={() => (
          <>
            <PasswordStrengthBar
              shortScoreWord={t('alerts.info.password.validateShort')}
              scoreWords={[
                t('alerts.info.password.validateWeak'),
                t('alerts.info.password.validateOkay'),
                t('alerts.info.password.validateGood'),
                t('alerts.info.password.validateStrong'),
                t('alerts.info.password.validateStronger'),
              ]}
              password={password}
            />
            <PasswordChecklistStyled
              rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
              minLength={8}
              iconSize={13}
              value={password}
              messages={{
                minLength: t('alerts.errors.password.minLength'),
                specialChar: t('alerts.errors.password.specialChar'),
                number: t('alerts.errors.password.noNumber'),
                capital: t('alerts.errors.password.capitalLetter'),
                lowercase: t('alerts.errors.password.lowercaseLetter'),
              }}
            />
          </>
        )}
      />

      <PrimaryButton type="submit" color="secondary">
        {t('register.createAccount')}
      </PrimaryButton>
    </>
  )
}

export const RegisterForm: FC<RegisterFormProps> = ({
  onSubmit,
  showTitle = true,
  showTokenText = false,
}) => {
  const { t } = useTranslation()

  return (
    <RegisterLayoutPage>
      <ConditionalRender
        condition={showTitle}
        render={() => (
          <Headline color="secondary" textTransform={'capitalize'}>
            {t('register.createAccount')}
          </Headline>
        )}
      />
      <ConditionalRender
        condition={showTokenText}
        render={() => <Body>{t('signup.correctTokenText')}</Body>}
      />

      <FormContainer<RegisterType> onSubmit={onSubmit} id="register">
        <RegisterFormContent />
      </FormContainer>

      <Divider text={t('core.otherOption')} />
      <SignInFormButtonContainer>
        <SecondaryButton color="secondary" navigateTo={'/login'}>
          {t('core.login')}
        </SecondaryButton>
      </SignInFormButtonContainer>
    </RegisterLayoutPage>
  )
}
