import { ConditionalRender } from '@core/components/ConditionalRender'
import { LogoIcon } from '@core/components/Icons/Logo'
import { Body } from '@core/components/Typography'
import useUserLoginHandler from '@core/hooks/useUserLoginStatus'
import { useTranslation } from '@core/providers/hooks/i18n'
import { usePageChecker } from '@hooks/usePageChecker'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import SellIcon from '@mui/icons-material/Sell'
import { Menu, MenuItem, SidebarWrapper } from './style'

const Sidebar = () => {
  const { t } = useTranslation()
  const { isAdmin } = useUserLoginHandler()
  const { isBuyPage, isHomePage, isSellPage, isAdminPage } = usePageChecker()

  return (
    <SidebarWrapper>
      <LogoIcon height="34" width="190" fill="#fff" />
      <Menu>
        <MenuItem className={isHomePage ? 'active' : ''} to={'/'}>
          <DashboardIcon height="18px" width="18px" />
          <Body color="primaryLight">{t('sidebar.dashboard')}</Body>
        </MenuItem>
        <MenuItem className={isSellPage ? 'active' : ''} to={'/sell/add'}>
          <SellIcon height="18px" width="18px" />
          <Body color="primaryLight">{t('sidebar.sell')}</Body>
        </MenuItem>
        <MenuItem className={isBuyPage ? 'active' : ''} to={'/buy/overview'}>
          <AddShoppingCartIcon />
          <Body color="primaryLight">{t('sidebar.buy')}</Body>
        </MenuItem>
        <ConditionalRender
          condition={isAdmin}
          render={() => (
            <MenuItem className={isAdminPage ? 'active' : ''} to={'/admin/tokens'}>
              <PermIdentityIcon />
              <Body color="primaryLight">{t('sidebar.admin')}</Body>
            </MenuItem>
          )}
        />
        {/* <MenuItem to={"/objectives"}>
          <ObjectivesIcon height="18px" width="18px" />
          {t("sidebar.objectives")}
        </MenuItem> */}
      </Menu>
    </SidebarWrapper>
  )
}

export default Sidebar
