import { Box } from '@mui/material'
import { get } from 'lodash'
import { ChangeEvent, useRef, useState } from 'react'
import { SortableItem } from 'react-easy-sort'
import { Controller, FieldValues, Path, PathValue, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ImageUploaderProps } from '..'
import { useGetInputProps } from '../../hooks/useGetInputProps'
import {
  BodyStyled,
  DeletePreviewIcon,
  DeletePreviewIconWrapper,
  ImageUploaderWrapper,
  PreviewImage,
  SortableListWrapper,
  TextFieldFileStyled,
} from './style'
import { TempImagesFilesType } from './types'

type ImagePreviewsProps = {
  previews: TempImagesFilesType
  handleListOrder: (oldIndex: number, newIndex: number) => void
  handleDeleteClick: (key: number) => void
}

const ImagePreviews = ({ previews, handleListOrder, handleDeleteClick }: ImagePreviewsProps) => {
  const onSortEnd = (oldIndex: number, newIndex: number) => {
    handleListOrder(oldIndex, newIndex)
  }

  return (
    <SortableListWrapper onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
      {previews.toOrder.map(key => {
        let image = previews.toEdit[key]
        if (!image) {
          image = URL.createObjectURL(previews.toAdd[key])
        }
        return (
          <SortableItem key={image}>
            <PreviewImage src={image}>
              <DeletePreviewIconWrapper onClick={() => handleDeleteClick(key)}>
                <DeletePreviewIcon height={'100%'} width={'100%'} />
              </DeletePreviewIconWrapper>
            </PreviewImage>
          </SortableItem>
        )
      })}
    </SortableListWrapper>
  )
}

export const ImageUploader = <T extends FieldValues>({
  fieldName,
  required,
  helperText,
  multiple,
  maxFiles,
}: ImageUploaderProps<T>) => {
  const context = useFormContext<T>()
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
    setValue,
    getValues,
  } = context

  const registerObj = {
    required,
  }
  const values = getValues()

  const [tempImageFiles, setTempImagesFiles] = useState<TempImagesFilesType>(
    values.tempImageFiles || {
      toAdd: {},
      toEdit: {},
      toRemove: {},
      toOrder: [],
    }
  )
  const maxOrderIndex = useRef<number>(
    tempImageFiles.toOrder.length ? Math.max(...tempImageFiles.toOrder) : 0
  )

  const inputProps = useGetInputProps({ required, helperText })

  const handleUpdates = (obj: TempImagesFilesType) => {
    setValue(`${fieldName}` as Path<T>, obj as PathValue<T, Path<T>>)
    setTempImagesFiles(obj)
  }

  const handleImagesToAdd = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files) return
    const filesArr = Array.from(files)
    const newImageObject = { ...tempImageFiles }

    filesArr.forEach(file => {
      maxOrderIndex.current++
      newImageObject.toAdd[maxOrderIndex.current] = file
      newImageObject.toOrder.push(maxOrderIndex.current)
    })
    handleUpdates(newImageObject)
  }

  const moveItem = (array: number[], fromIndex: number, toIndex: number) => {
    const item = array.splice(fromIndex, 1)[0]
    array.splice(toIndex, 0, item)
  }

  const handleListOrder = (oldIndex: number, newIndex: number) => {
    const newImageObject = { ...tempImageFiles }
    moveItem(newImageObject.toOrder, oldIndex, newIndex)
    handleUpdates(newImageObject)
  }

  const handleImagesToDelete = (key: number) => {
    const newImageObject = { ...tempImageFiles }
    const isLocal = newImageObject.toAdd[key]
    if (isLocal) {
      delete newImageObject.toAdd[key]
    } else {
      newImageObject.toRemove[key] = newImageObject.toEdit[key]
      delete newImageObject.toEdit[key]
    }
    newImageObject.toOrder = newImageObject.toOrder.filter(x => x !== key)
    handleUpdates(newImageObject)
  }

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        rules={registerObj}
        render={({ field: { onChange } }) => {
          return (
            <ImageUploaderWrapper>
              <BodyStyled align="center">{t('form.imageUploader.addImage')}</BodyStyled>
              <TextFieldFileStyled
                type="file"
                onChange={e => {
                  onChange(e)
                  const event = e as ChangeEvent<HTMLInputElement>
                  handleImagesToAdd(event)
                }}
                variant="outlined"
                fullWidth
                margin="normal"
                error={!!get(errors, fieldName)}
                id={fieldName}
                {...inputProps}
                inputProps={{
                  multiple,
                  maxFiles,
                }}
                InputProps={{
                  componentsProps: {
                    root: {
                      style: {
                        background: 'none',
                        height: '100%',
                      },
                    },
                  },
                }}
              />
              <Box
                display={'grid'}
                height={'100%'}
                maxHeight={400 - 72} // this is calculated as the height of the block - the height of the file input element
                overflow={'auto'}
                padding={'16px'}
              >
                <ImagePreviews
                  previews={tempImageFiles}
                  handleListOrder={handleListOrder}
                  handleDeleteClick={handleImagesToDelete}
                />
              </Box>
            </ImageUploaderWrapper>
          )
        }}
      />
    </>
  )
}
