import { AppConfig } from '@customTypes/app'

const safeEnv = (key: string) => {
  const value = import.meta.env[key]
  if (value === undefined) throw new Error(`Missing env variable ${key}`)
  return value
}

const boolean = (value: string) => {
  return Boolean(value && value.toLowerCase && value.toLowerCase() === 'true')
}

const config: AppConfig = {
  env: safeEnv('VITE_ENV'),
  bff: {
    url: safeEnv('VITE_BFF_URL'),
  },
  features: {
    mediaSupported: boolean(safeEnv('VITE_TOGGLE_MEDIA_SUPPORTED')),
  },
  socket: {
    url: safeEnv('VITE_SOCKET_URL'),
  },
}

export default config
