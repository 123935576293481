import { createContext, FC, useState } from 'react'
import { OrderStatus } from '../components/CheckoutModal'
import { OrderContextData, OrderProviderProps } from './types'

const initialContextData: OrderContextData = {
  orderStatus: undefined,
  setOrderStatus: () => {},
}

export const OrderContext = createContext<OrderContextData>(initialContextData)

export const OrderProvider: FC<OrderProviderProps> = ({ children }) => {
  const [orderStatus, setOrderStatus] = useState<OrderStatus>()

  return (
    <OrderContext.Provider value={{ orderStatus, setOrderStatus }}>
      <>{children}</>
    </OrderContext.Provider>
  )
}
