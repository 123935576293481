import { LeftArrowIcon } from 'core/src/components/Icons/LeftArrow'
import { IconProps } from 'core/src/components/Icons/types'
import { FC } from 'react'
import { LinkTypography } from '../../../components/Typography'
import { BackToLinkStyle, LoginFormContainer, LoginWrapper, SignupWrapper } from './style'

export type SignInLayoutPageProps = {
  children: React.ReactNode
  className?: string
}

export type LinkGoToProps = {
  to: string
  label: string
  Icon?: (props: IconProps) => JSX.Element
  color?: string
  underlined?: boolean
  alignment?: 'flex-start' | 'flex-end' | 'center'
}

export const SignInLayoutPage: FC<SignInLayoutPageProps> = ({ children, className }) => {
  return (
    <LoginWrapper className={className}>
      <LoginFormContainer>{children}</LoginFormContainer>
    </LoginWrapper>
  )
}
export const RegisterLayoutPage: FC<SignInLayoutPageProps> = ({ children, className }) => {
  return (
    <SignupWrapper className={className}>
      <LoginFormContainer>{children}</LoginFormContainer>
    </SignupWrapper>
  )
}

export const LinkGoTo: FC<LinkGoToProps> = ({ to, label, Icon, color, underlined, alignment }) => {
  return (
    <LinkTypography href={to} color={color as string} underline={underlined} alignment={alignment}>
      {Icon && <Icon />}
      {label}
    </LinkTypography>
  )
}

export const LinkBackTo: FC<LinkGoToProps> = props => {
  return (
    <BackToLinkStyle>
      <LinkGoTo
        {...props}
        color="primaryLight"
        Icon={() => <LeftArrowIcon fill="white" stroke="white" height="12px" width="12px" {...props} />}
      />
    </BackToLinkStyle>
  )
}
