import styled, { css } from 'styled-components'
import { Title } from '../Typography'
type GridDirection = 'row' | 'column'

export const FormStepSectionLayout = styled.div<{
  divisions?: number
  direction?: GridDirection
  marginBottom?: string
}>`
  display: grid;
  ${({ divisions, direction }) =>
    `grid-template-${direction === 'row' ? 'rows' : 'columns'}: repeat(${divisions ? divisions : 3}, 1fr)`};
  ${({ marginBottom }) => `margin-bottom: ${marginBottom || 0}px;`}
  gap: 20px 30px;

  > * {
    justify-content: flex-start;
  }
`

export const FormStepTitle = styled(Title)`
  margin-bottom: 30px;
`

export const borderRadiusCSS = css`
  border-radius: 4px;
`

export const formElementCSS = css`
  display: flex;
  align-content: center;
  min-height: 56px;
  min-width: 238px;
`

interface FormElementBoxProps {
  hasErrors: boolean
}

export const FormElementContainer = styled.div`
  position: relative;
  margin: 8px 0;
`

export const FormElementTitle = styled.label(({ theme }) => {
  const padding = '5px'

  return `
    ${enrichmentTextCss};
    position: absolute;
    top: -8px;
    left: calc(${enrichmentTextSpacing} - ${padding});
    background: white;
    padding: 0 ${padding};
    color: ${theme.palette.divider};
  `
})

export const FormElementBox = styled.div<FormElementBoxProps>`
  ${borderRadiusCSS}
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-color: ${({ theme, hasErrors }) => (hasErrors ? theme.palette.error.main : 'inherits')};

  ${FormElementTitle} {
    color: ${({ theme, hasErrors }) => (hasErrors ? theme.palette.error.main : 'inherits')};
  }
`

export const enrichmentTextSpacing = '15px'
const enrichmentTextCss = css`
  font-size: 12px;
`

export const FormElementDescription = styled.label`
  ${enrichmentTextCss};
  margin-left: ${enrichmentTextSpacing};
  color: ${({ theme }) => theme.palette.divider};
`

const formWrapperSpacing = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  ${formWrapperSpacing}
  form {
    ${formWrapperSpacing}
    margin-top: 20px;
    .MuiFormControl-root {
      width: 100%;
    }
  }
`
