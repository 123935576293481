import { useFullscreenLoader } from 'core/src/hooks/useFullscreenLoader'
import { isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { getFormResetOptions } from '../../../utils/form'
import { FormWrapper } from '../style'
import { FormContainerProps } from './type'

const FormContainer = <T extends FieldValues>({
  children,
  onSubmit,
  initData,
  error,
  className,
  id,
  mode = 'onSubmit',
}: FormContainerProps<T>) => {
  const [loading, setLoading] = useState(false)

  const methods = useForm<T>({
    resetOptions: getFormResetOptions(),
    defaultValues: initData,
  })

  // TODO: quando c'è un evento di navigazione il loading potrebbe rimanere appeso
  // quindi bisogna mettere un listener che ad ogni atto di navigazione elimina la lista
  // del FullscreenLoader, o valutare altre soluzioni
  useFullscreenLoader(`formContainer${id ? `-${id}` : ''}`, [loading])

  const { handleSubmit: handleSubmitReactForm, reset, setError, getValues, watch } = methods
  const formData = watch()
  const prevFormData = useRef(getValues())

  const handleSubmit = handleSubmitReactForm(async (data: T) => {
    setLoading(true)
    try {
      await onSubmit(data)
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  })

  useEffect(() => {
    // this effect changes based on "formData" and it changes with the "watch" function under the hood.
    // first of all we activate this logic only when we want to use the form without the submit button, so, in onChange mode.
    if (mode === 'onChange') {
      if (!isEqual(formData, prevFormData.current)) {
        handleSubmit(formData as any)
        prevFormData.current = getValues()
      }
    }
  }, [initData, mode, formData, handleSubmit, getValues])

  useEffect(() => {
    reset(initData)
  }, [initData, reset])

  useEffect(() => {
    if (error) {
      setError(error.fieldName, { type: 'custom', message: error.message })
    }

    // do not add "error" in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error?.message, error?.fieldName, methods])

  return (
    <FormWrapper className={className}>
      <FormProvider<T> {...methods}>
        <form onSubmit={handleSubmit}>{children}</form>
      </FormProvider>
    </FormWrapper>
  )
}

export default FormContainer
