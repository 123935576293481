import BaseWrapper from '@components/BaseWrapper'
import { FormCompleteHandler } from '@core/components/Form/FormContainer/type'
import { RegisterForm, RegisterType } from '@core/components/RegisterForm'
import { useGraphqlMutation } from '@core/hooks/queries/graphqlHooks'
import { AstautoReduxStore } from '@customTypes/AstautoReduxStore'
import { SignInWrapper } from '@privatePages/signin/login/style'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const RegisterPage = () => {
  const navigate = useNavigate()
  const [createAdvancedUserCb] = useGraphqlMutation<{ createAdvancedUser: string }>('createAdvancedUser')
  const license = useSelector((s: AstautoReduxStore) => s.user.account.license)

  const onSubmit: FormCompleteHandler<RegisterType> = async formData => {
    const { email, password } = formData

    const { data } = await createAdvancedUserCb({
      variables: {
        email,
        password,
        license,
      },
    })

    if (data?.createAdvancedUser) {
      navigate('/confirm-email')
    }
  }

  return (
    <BaseWrapper disablePadding hideSidebar hideToolbar headerComponent={<></>}>
      <SignInWrapper>
        <RegisterForm onSubmit={onSubmit} />
      </SignInWrapper>
    </BaseWrapper>
  )
}

export default RegisterPage
