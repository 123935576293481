import styled from 'styled-components'
import noCar from '../../assets/images/noData/no_cars.png'
import noFavorites from '../../assets/images/noData/no_favorites.png'
import noOrders from '../../assets/images/noData/no_orders.png'
import screenSizeTooSmall from '../../assets/images/noData/screen_size_too_small.png'
import { useTranslation } from '../../providers/hooks/i18n'
import { PrimaryButton } from '../Button'
import { ConditionalRender } from '../ConditionalRender'
import { Body, Title } from '../Typography'
import { NoDataComponentWrapper } from './style'
import { NoDataComponentTypes } from './types'

const imageMapper = {
  noCars: noCar,
  noOrders: noOrders,
  noArticles: noCar,
  noFavorites: noCar,
  emptyCart: noCar,
  noBids: noFavorites,
  screenSizeTooSmall: screenSizeTooSmall,
  notFound: noCar,
}

export const NoDataImage = styled.div``
export const NoDataText = styled.div``

type NoDataComponentProps = {
  type: NoDataComponentTypes
  action?: () => void
  className?: string
}

export const NoDataComponent = ({ type, action, className }: NoDataComponentProps) => {
  const { t } = useTranslation()
  return (
    <NoDataComponentWrapper className={className}>
      <NoDataImage>
        <img src={imageMapper[type]} />
      </NoDataImage>
      <NoDataText>
        <Title>{t(`alerts.info.noDataMessages.${type}.title`)}</Title>
        <Body>{t(`alerts.info.noDataMessages.${type}.message`)}</Body>
        <ConditionalRender
          condition={action}
          render={action => (
            <PrimaryButton onClick={action}>{t(`alerts.info.noDataMessages.${type}.action`)}</PrimaryButton>
          )}
        />
      </NoDataText>
    </NoDataComponentWrapper>
  )
}
