import BaseWrapper from '@components/BaseWrapper'
import { FormCompleteHandler } from '@core/components/Form/FormContainer/type'
import { LoginFormSignIn, LoginFormSignInType } from '@core/components/LoginFormSignIn'
import { useGraphqlLazy } from '@core/hooks/queries/graphqlHooks'
import { useActions } from '@core/hooks/useActions'
import { setUser } from '@core/services/state/user'
import { User } from '@core/types/graphqlTypes'
import { setAccessToken } from '@core/utils/auth'
import { SignInWrapper } from '@privatePages/signin/login/style'
import { useNavigate } from 'react-router-dom'

const LoginPage = () => {
  const navigate = useNavigate()
  const [loginCb] = useGraphqlLazy<{
    login: User
  }>('login', undefined, { cachePolicy: 'network-only' })

  const actions = useActions({ setUser })
  // useErrorManager('loginUserError', [error])

  const onSubmit: FormCompleteHandler<LoginFormSignInType> = async formData => {
    const { email, password } = formData
    const { data } = await loginCb({ variables: { email, password } })
    if (data?.login) {
      actions.setUser(data.login)
      const accessToken = data.login.account.accessToken || ''
      setAccessToken(accessToken)
      navigate('/')
    }
  }

  return (
    <BaseWrapper disablePadding hideSidebar hideToolbar headerComponent={<></>}>
      <SignInWrapper>
        <LoginFormSignIn onSubmit={onSubmit} createAccountPath="/license" />
      </SignInWrapper>
    </BaseWrapper>
  )
}

export default LoginPage
