import { WatchQueryFetchPolicy, useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'

import { useFullscreenLoader } from 'core/src/hooks/useFullscreenLoader'
import { merge } from 'lodash'
import { QueryName, mapQueryNameFragments } from '.'

interface QueryOptions {
  cachePolicy?: WatchQueryFetchPolicy
  loaderEnabled?: boolean
}

export const getQueryByName = (queryName: QueryName) => {
  const query = mapQueryNameFragments[queryName]
  if (!query) {
    throw new Error(`Query ${queryName} does not exist`)
  }
  return query
}

type MutationOptions = {
  clearCacheIds?: string[]
}

export const useGraphqlMutation = <Res>(queryName: QueryName, options: MutationOptions = {}) => {
  const query = getQueryByName(queryName)
  const client = useApolloClient()
  const { clearCacheIds } = options

  const mutationObj = useMutation<Res>(query, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      if (!clearCacheIds || !clearCacheIds.length) return

      const { cache } = client

      clearCacheIds.forEach(fieldName => {
        cache.evict({ id: 'ROOT_QUERY', fieldName })
      })

      cache.gc()
    },
  })

  const { loading } = mutationObj[1]

  useFullscreenLoader(`graphqlQueries-${queryName}`, [loading])
  return mutationObj
}

export const useGraphqlLazy = <Res>(queryName: QueryName, variables = {}, optionsArgs?: QueryOptions) => {
  const defaultOptions = { cachePolicy: 'cache-and-network', loaderEnabled: true }
  const options = merge(defaultOptions, optionsArgs)
  const query = getQueryByName(queryName)
  const queryObject = useLazyQuery<Res>(query, {
    variables,
    fetchPolicy: options.cachePolicy,
  })
  const { loading } = queryObject[1]
  useFullscreenLoader(`graphqlQueries-${queryName}`, [loading], options.loaderEnabled)

  return queryObject
}

export const useGraphqlQuery = <Res>(queryName: QueryName, variables = {}, optionsArgs?: QueryOptions) => {
  const defaultOptions = { cachePolicy: 'cache-and-network', loaderEnabled: true }
  const options = merge(defaultOptions, optionsArgs)
  const query = getQueryByName(queryName)

  const { data, loading, error, refetch } = useQuery<Res>(query, {
    variables,
    fetchPolicy: options.cachePolicy,
  })

  useFullscreenLoader(`graphqlQueries-${queryName}`, [loading], options.loaderEnabled)

  return {
    data,
    error,
    loading,
    refetch,
  }
}
