import BaseWrapper from '@components/BaseWrapper'
import { FormCompleteHandler } from '@core/components/Form/FormContainer/type'
import { FormResetPassword, FormResetPasswordType } from '@core/components/FormResetPassword'
import { useGraphqlMutation } from '@core/hooks/queries/graphqlHooks'
import { useActions } from '@core/hooks/useActions'
import { setForgotPasswordMail } from '@core/services/state/user'
import { SignInWrapper } from '@privatePages/signin/login/style'
import { useNavigate } from 'react-router-dom'

const ForgotPasswordPage = () => {
  const navigate = useNavigate()
  const actions = useActions({ setForgotPasswordMail })
  const [resetPasswordCb, { error }] = useGraphqlMutation('resetPassword')
  // useErrorManager('forgotPasswordError', [error])

  const resetPasswordSubmit: FormCompleteHandler<FormResetPasswordType> = async data => {
    await resetPasswordCb({
      variables: {
        email: data.email,
      },
    })
    if (error) {
    }
    actions.setForgotPasswordMail(data.email)
    navigate('/forgotPassword/update')
  }

  return (
    <BaseWrapper disablePadding hideSidebar hideToolbar headerComponent={<></>}>
      <SignInWrapper>
        <FormResetPassword onSubmit={resetPasswordSubmit} />
      </SignInWrapper>
    </BaseWrapper>
  )
}

export default ForgotPasswordPage
