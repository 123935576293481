import { Tab } from '@mui/material'
import styled from 'styled-components'

export const TabsWrapper = styled.div`
  margin-bottom: 20px;
`

export const StyledTabsList = styled.div`
  gap: 50px;
  display: flex;
  height: 40px;
  border-bottom: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};
`

export const StyledTab = styled(Tab)`
  cursor: pointer;
  position: relative;
`
