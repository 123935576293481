import { ConditionalRender } from 'core/src/components/ConditionalRender'
import { FC } from 'react'
import { DividerStyle, DividerText } from './style'
import { DividerProps } from './types'

const Divider: FC<DividerProps> = ({ text }) => (
  <DividerStyle>
    <ConditionalRender condition={text} render={text => <DividerText>{text}</DividerText>} />
  </DividerStyle>
)

export default Divider
