import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { UserAuthorizations } from '../types/graphqlTypes'
import { useGraphqlQuery } from './queries/graphqlHooks'

const useUserLoginHandler = () => {
  const { data } = useGraphqlQuery<{ getMyAuthorizations: UserAuthorizations }>(
    'getMyAuthorizations',
    {},
    {
      cachePolicy: 'cache-and-network',
    }
  )

  const user = useSelector((s: any) => s.user)
  const loginInfo = useMemo(() => {
    const isLoggedIn = !!user.uuid
    const userId = user.uuid

    return { isLoggedIn, userId, user }
  }, [user])

  const isAdmin = data?.getMyAuthorizations.isAdmin || false
  const isPartner = data?.getMyAuthorizations.isPartner || false

  return {
    ...loginInfo,
    isAdmin,
    isPartner,
  }
}

export default useUserLoginHandler
