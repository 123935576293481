import styled from 'styled-components'

export const NoDataComponentWrapper = styled.div`
  display: flex;
  /* grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)); */
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  text-align: center;
  flex-direction: column;
  gap: 10px;

  img {
    max-width: 300px;
    width: 100%;
    margin-bottom: 10px;
  }

  button {
    margin-top: 20px;
  }
`
