import { FC } from 'react'
import { LinkBackTo, SignInLayoutPage } from '../../pages/private/signIn'
import { useTranslation } from '../../providers/hooks/i18n'
import { PrimaryButton } from '../Button'
import useFormBuilder from '../Form'
import FormContainer from '../Form/FormContainer'
import { FormCompleteHandler } from '../Form/FormContainer/type'

export type FormResetPasswordType = {
  email: string
}

interface FormResetPasswordProps {
  onSubmit: FormCompleteHandler<FormResetPasswordType>
}

export const FormResetPassword: FC<FormResetPasswordProps> = ({ onSubmit }) => {
  const { InputText } = useFormBuilder<FormResetPasswordType>()
  const { t } = useTranslation()

  return (
    <SignInLayoutPage>
      <FormContainer<FormResetPasswordType> onSubmit={onSubmit} id="password">
        <InputText label={t('form.email')} fieldName="email" type="email" required />
        <PrimaryButton type="submit" color="secondary">
          {t('login.passwordRecovery')}
        </PrimaryButton>
      </FormContainer>
      <LinkBackTo label={t('login.backToLoginPage')} to="/login" />
    </SignInLayoutPage>
  )
}
