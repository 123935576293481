import styled from 'styled-components'
import { RadioControlLabel, RadioGroup } from '../Radio/styles'

export const ChipContainer = styled.span`
  ${RadioGroup} {
    flex-direction: row;
  }

  ${RadioControlLabel} {
    width: fit-content;
    border: 1px solid ${({ theme }) => theme.palette.secondary.main};
    border-radius: 20px;
    padding: 5px 15px;
    margin-bottom: 5px;

    &:has(> .Mui-checked) {
      background: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`
