import { FormControlLabel, Switch as MaterialSwitch } from '@mui/material'
import { Controller, FieldValues } from 'react-hook-form'
import { useFormContext } from '../../hooks/useFormContext'
import { FormFieldValue } from '../types'
import { SwitchProps } from './types'

const Switch = <T extends FieldValues>({ label, fieldName, required }: SwitchProps<T>) => {
  const { control } = useFormContext<T>()

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={false as FormFieldValue<T>}
      rules={{ required }}
      render={({ field: { value, onChange } }) => {
        return (
          <FormControlLabel
            label={label}
            control={<MaterialSwitch checked={value as boolean} onChange={onChange} id={fieldName} />}
          />
        )
      }}
    />
  )
}

export default Switch
