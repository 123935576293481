import { gql } from '@apollo/client'
import { Car } from './car'

// TODO: carPaginated lo usiamo quando mostriamo un car tile, ottimizziamo il fragment, sicuramente non ci serve tutto il Car
export const CarPaginated = gql`
  ${Car}
  fragment CarPaginated on CarPaginated {
    list {
      ...Car
    }
    totalResults
  }
`
