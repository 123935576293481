import Sidebar from '@components/Sidebar'
import ToolBar from '@components/Toolbar'
import { ConditionalRender } from '@core/components/ConditionalRender'
import { LoadingLayer } from '@core/components/LoadingLayer'
import { headerComponentClassName } from '@core/constants'
import { useWindowResizeListener } from '@core/hooks/useNavigationUtils'
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { InnerWrapper, Wrapper } from './style'
import { BaseWrapperProps } from './types'

const BaseWrapper: FC<BaseWrapperProps> = ({
  children,
  className,
  hideSidebar = false,
  hideToolbar = false,
  disablePadding = false,
  headerComponent,
}) => {
  const location = useLocation()

  const header = useRef<HTMLDivElement>(null)
  const toolbar = useRef<HTMLDivElement>(null)
  const headerHeight = useRef<number>(0)
  const toolbarHeight = useRef<number>(0)
  const { height } = useWindowResizeListener()
  const [contentHeight, setContentHeight] = useState(500)

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  const updateContentHeight = useCallback(() => {
    headerHeight.current = (header.current && header.current.clientHeight) || 0
    toolbarHeight.current = (toolbar.current && toolbar.current.clientHeight) || 0
    setContentHeight(height - headerHeight.current - toolbarHeight.current - (disablePadding ? 0 : 30))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disablePadding, height, headerHeight.current])

  useLayoutEffect(() => {
    updateContentHeight()
  }, [height, headerHeight, toolbarHeight, updateContentHeight])

  const DefaultHeaderComponent = () => <></>

  const HeaderComponent = headerComponent || <DefaultHeaderComponent />

  useEffect(() => {
    const head = header && header.current
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === head) {
          headerHeight.current = entry.contentRect.height
          updateContentHeight()
        }
      }
    })

    if (head) {
      observer.observe(head)
    }

    return () => {
      if (head) {
        observer.unobserve(head)
      }
    }
  }, [updateContentHeight])

  return (
    <>
      <LoadingLayer />
      <ConditionalRender condition={!hideSidebar} render={() => <Sidebar />} />
      <Wrapper className={className}>
        <div ref={toolbar}>
          <ConditionalRender condition={!hideToolbar} render={() => <ToolBar />} />
        </div>
        <div ref={header} className={headerComponentClassName}>
          {HeaderComponent}
        </div>

        <InnerWrapper sx={{ height: contentHeight }} disablePadding={disablePadding}>
          {children}
        </InnerWrapper>
      </Wrapper>
    </>
  )
}

export default BaseWrapper
