import { TextField } from '@mui/material'
import { get } from 'lodash'
import { Controller, FieldValues } from 'react-hook-form'
import { useFormContext } from '../../hooks/useFormContext'
import { useGetInputProps } from '../../hooks/useGetInputProps'
import { InputPhoneProps } from './types'

const InputPhone = <T extends FieldValues>({
  fieldName,
  required,
  type,
  label,
  helperText,
  maxLength,
  minLength,
  disabled,
  onFocus,
  startAdornment,
}: InputPhoneProps<T>) => {
  const context = useFormContext<T>()
  const inputProps = useGetInputProps({ required, helperText, startAdornment })

  const {
    control,
    formState: { errors },
  } = context

  const registerObj = {
    required,
    valueAsNumber: type === 'number',
    maxLength: maxLength ? maxLength : undefined,
    minLength: minLength ? minLength : undefined,
  }

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={registerObj}
      render={({ field: { value, onChange } }) => (
        <TextField
          {...inputProps}
          disabled={disabled}
          onFocus={onFocus}
          onChange={value => {
            onChange(value)
          }}
          error={!!get(errors, fieldName)}
          label={label}
          helperText={helperText || ' '}
          id={fieldName}
          defaultValue={value}
          type={type}
          // InputProps={{
          //   startAdornment: '+39',
          // }}
        />
      )}
    />
  )
}

export default InputPhone
