import styled from 'styled-components'

export const DividerStyle = styled.div`
  color: white;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  min-height: 2px;

  &:before,
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 100vw;
    background: white;
  }
`

export const DividerText = styled.span`
  padding: 0 10px;
  font-weight: normal;
`
