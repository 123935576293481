import { gql } from '@apollo/client'
import { Car } from './car'
import { Facets } from './facet'

export const CarWithFacets = gql`
  ${Car}
  ${Facets}
  fragment CarWithFacets on CarWithFacets {
    list {
      ...Car
    }
    totalResults
    facets {
      ...Facets
    }
  }
`
