import { Box, FormControlLabel, Grid, Slider as MaterialSlider } from '@mui/material'
import { Controller, FieldValues } from 'react-hook-form'
import { Body } from '../../../Typography'
import { useFormContext } from '../../hooks/useFormContext'
import { FormFieldValue } from '../types'
import { SliderProps } from './types'

export const SLIDER_MIN_DEFAULT = 1000
export const SLIDER_MAX_DEFAULT = 100000
const STEP_DEFAULT = 500

const Slider = <T extends FieldValues>({
  label,
  fieldName,
  required,
  disabled,
  range,
  ...props
}: SliderProps<T>) => {
  const { control } = useFormContext<T>()
  const { min: minProps, max: maxProps } = range
  const min = minProps ?? SLIDER_MIN_DEFAULT
  const max = maxProps ?? SLIDER_MAX_DEFAULT
  const step = props.step ?? STEP_DEFAULT

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={[min, max] as FormFieldValue<T>}
      rules={{ required }}
      render={({ field }) => {
        return (
          <FormControlLabel
            label={''}
            sx={{ marginInline: 0 }}
            control={
              <Box sx={{ width: '100%' }}>
                <Body gutterBottom>{label}</Body>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <MaterialSlider
                      {...field}
                      valueLabelDisplay="auto"
                      min={SLIDER_MIN_DEFAULT}
                      marks={[
                        { value: SLIDER_MIN_DEFAULT, label: '' },
                        { value: SLIDER_MAX_DEFAULT, label: '' },
                      ]}
                      max={SLIDER_MAX_DEFAULT}
                      step={step}
                      disabled={disabled}
                      color="secondary"
                      sx={{ width: '96%', marginLeft: '2%' }}
                      onChange={(_, value) => {
                        field.onChange(value)
                      }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Body>{SLIDER_MIN_DEFAULT}</Body>
                      <Body>{SLIDER_MAX_DEFAULT}</Body>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            }
          />
        )
      }}
    />
  )
}

export default Slider
