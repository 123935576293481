import { FormControlLabel, Checkbox as MaterialCheckbox } from '@mui/material'
import { Controller, FieldValues } from 'react-hook-form'
import { useFormContext } from '../../hooks/useFormContext'
import { FormFieldValue } from '../types'
import { CheckboxProps } from './types'

const Checkbox = <T extends FieldValues>({ label, fieldName, required }: CheckboxProps<T>) => {
  const { control } = useFormContext<T>()
  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{ required }}
      defaultValue={false as FormFieldValue<T>}
      render={({ field: { value, onChange } }) => {
        return (
          <FormControlLabel
            label={label}
            control={<MaterialCheckbox checked={value as boolean} onChange={onChange} id={fieldName} />}
          />
        )
      }}
    />
  )
}

export default Checkbox
