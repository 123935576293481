import { gql } from '@apollo/client'
import { Bid } from './bid'

export const HighestBids = gql`
  ${Bid}
  fragment HighestBids on HighestBids {
    bid {
      ...Bid
    }
  }
`
