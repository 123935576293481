import { PageKeys, Pages } from '@components/Tabs/types'
import { keys } from 'lodash'
import { useMatch } from 'react-router-dom'

export const usePageChecker = () => {
  const isHomePage = !!useMatch({ path: `/home/*` })
  const isBuyPage = !!useMatch({ path: `/buy/*` })
  const isSellPage = !!useMatch({ path: `/sell/*` })
  const isAdminPage = !!useMatch({ path: `/admin/*` })
  const isObjectivesPage = !!useMatch({ path: `/objectives/*` })

  const pageChecker: Pages = {
    isHomePage,
    isBuyPage,
    isSellPage,
    isObjectivesPage,
    isAdminPage,
  }

  const pageCheckerKeys = keys(pageChecker) as PageKeys[]
  const currentPage = pageCheckerKeys.find(page => pageChecker[page])

  return {
    currentPage,
    ...pageChecker,
  }
}
