import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type HomepageLayout = ReactGridLayout.Layouts[]

const initialState: HomepageLayout = [
  {
    lg: [
      { w: 5, h: 1, x: 0, y: 0, i: 'bannerSlot', static: true },
      { w: 1, h: 4, x: 0, y: 1, i: 'profile', static: true },
      {
        w: 2,
        h: 2,
        x: 1,
        y: 1,
        i: 'yourCars',
        minW: 2,
        minH: 2,
        static: true,
      },
      {
        w: 2,
        h: 2,
        x: 1,
        y: 3,
        i: 'tutorial',
        minW: 2,
        minH: 2,
        static: true,
      },
      {
        w: 2,
        h: 2,
        x: 3,
        y: 1,
        i: 'bestCarForYou',
        minW: 2,
        minH: 2,
        static: true,
      },
      {
        w: 2,
        h: 2,
        x: 3,
        y: 3,
        i: 'plans',
        minW: 2,
        minH: 2,
        static: true,
      },
    ],
    md: [
      { w: 2, h: 1, x: 0, y: 0, i: 'bannerSlot', static: true },
      { w: 1, h: 4, x: 0, y: 1, i: 'profile', static: true },
      { w: 2, h: 2, x: 1, y: 1, i: 'yourCars', minH: 2, static: true },
      { w: 2, h: 2, x: 1, y: 5, i: 'tutorial', minH: 2, static: true },
      {
        w: 2,
        h: 2,
        x: 1,
        y: 3,
        i: 'bestCarForYou',
        minH: 2,
        static: true,
      },
      { w: 1, h: 2, x: 0, y: 5, i: 'plans', minH: 2, static: true },
    ],
    sm: [
      { w: 2, h: 1, x: 0, y: 0, i: 'bannerSlot', static: true },
      { w: 1, h: 4, x: 0, y: 1, i: 'profile', static: true },
      { w: 2, h: 2, x: 0, y: 5, i: 'yourCars', minH: 2, static: true },
      { w: 2, h: 2, x: 0, y: 7, i: 'tutorial', minH: 2, static: true },
      {
        w: 1,
        h: 2,
        x: 1,
        y: 1,
        i: 'bestCarForYou',
        minH: 2,
        static: true,
      },
      { w: 1, h: 2, x: 1, y: 3, i: 'plans', minH: 2, static: true },
    ],
    xs: [
      { w: 2, h: 1, x: 0, y: 0, i: 'bannerSlot', static: true },
      { w: 2, h: 4, x: 0, y: 1, i: 'profile', static: true },
      { w: 2, h: 2, x: 0, y: 5, i: 'yourCars', minH: 2, static: true },
      { w: 2, h: 2, x: 0, y: 7, i: 'tutorial', minH: 2, static: true },
      {
        w: 2,
        h: 2,
        x: 1,
        y: 1,
        i: 'bestCarForYou',
        minH: 2,
        static: true,
      },
      { w: 2, h: 2, x: 1, y: 3, i: 'plans', minH: 2, static: true },
    ],
  },
]

const homepageLayoutSlice = createSlice({
  name: 'HOMEPAGE_LAYOUT',
  initialState,
  reducers: {
    saveLayout: (_, action: PayloadAction<HomepageLayout>) => {
      return action.payload
    },
  },
})

export const { saveLayout } = homepageLayoutSlice.actions
export default homepageLayoutSlice.reducer
