import styled from 'styled-components'

export const ToolbarWrapper = styled.nav`
  width: 100%;
  background: white;
  position: sticky;
  top: 0;
  padding: 30px clamp(36px, 3%, 120px) 0 clamp(36px, 3%, 120px);
  z-index: 9;
`

export const ToolbarFirstLevel = styled.nav`
  gap: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
`
