import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { TextField } from '@mui/material'
import { get } from 'lodash'
import { Controller, FieldValues } from 'react-hook-form'
import { useThemeContext } from '../../../../providers/hooks'
import { useFormContext } from '../../hooks/useFormContext'
import { useGetInputProps } from '../../hooks/useGetInputProps'
import { FormFieldValue } from '../types'
import { SecondaryButtonStyled, StepperSelectorWrapper } from './style'
import { StepperSelectorProps } from './types'

const StepperSelector = <T extends FieldValues>({
  fieldName,
  required,
  type,
  label,
  helperText,
  disabled,
  step,
  onFocus,
  startAdornment,
  endAdornment,
  defaultValue = '',
}: StepperSelectorProps<T>) => {
  const context = useFormContext<T>()
  const theme = useThemeContext()
  const inputProps = useGetInputProps({ required, helperText, startAdornment, endAdornment })
  const {
    control,
    formState: { errors },
  } = context

  const registerObj = {
    required,
    valueAsNumber: type === 'number',
  }

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={registerObj}
      defaultValue={defaultValue as FormFieldValue<T>}
      render={({ field: { value, onChange } }) => {
        // onChange(containerValue || value)
        return (
          <StepperSelectorWrapper>
            <SecondaryButtonStyled
              disabled={parseInt(value) === 0}
              onClick={() => {
                if (parseInt(value || '0') === 0) return
                onChange(parseInt(value || '0') - step)
              }}
              variant="outlined"
            >
              <RemoveIcon />
            </SecondaryButtonStyled>
            <TextField
              disabled={disabled}
              onFocus={onFocus}
              onChange={event => {
                let value: string | number = event.target.value
                if (type === 'number') {
                  value = parseInt(value)
                }
                onChange(value)
              }}
              error={!!get(errors, fieldName)}
              label={label}
              helperText={helperText || ' '}
              id={fieldName}
              value={defaultValue || value}
              type={type}
              {...inputProps}
              InputProps={{
                endAdornment,
                readOnly: true,
                componentsProps: {
                  root: {
                    style: {
                      borderRadius: '0',
                      background: theme.palette.background.paper,
                    },
                  },
                },
              }}
            />
            <SecondaryButtonStyled onClick={() => onChange(parseInt(value || '0') + step)} variant="outlined">
              <AddIcon />
            </SecondaryButtonStyled>
          </StepperSelectorWrapper>
        )
      }}
    />
  )
}

export default StepperSelector
