import { useMemo } from 'react'
import { useThemeContext } from '../../../providers/hooks'

interface InputConfigProps {
  required?: boolean
  helperText?: string
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  background?: string
  shrink?: boolean
}

export const useGetInputProps = ({
  required,
  helperText,
  startAdornment,
  endAdornment,
  background,
  shrink,
}: InputConfigProps) => {
  const theme = useThemeContext()

  return useMemo(
    () => ({
      InputLabelProps: {
        required: !!required,
        shrink,
        sx: {
          backgroundColor: background || theme.palette.background.paper,
          'border-radius': '10px',
          padding: '0px 10px',
          fontSize: '18px',
        },
      },
      InputProps: {
        sx: {
          backgroundColor: background || theme.palette.background.paper,
          borderColor: theme.palette.background.paper,
          borderRadius: '12px',
          boxShadow: 'rgba(0,0,0,0.20) 0px 2px 6px',
        },
        startAdornment,
        endAdornment,
      },
      FormHelperTextProps: {
        title: helperText,
        sx: {
          display: helperText ? 'inherit' : 'none',
        },
      },
    }),
    [required, theme.palette.background.paper, background, startAdornment, endAdornment, helperText]
  )
}
