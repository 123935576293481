import { IconProps } from './types'

export const LeftArrowIcon = ({
  width = '1rem',
  height = '1rem',
  stroke = '#333',
  fill = 'none',
  className = 'customIcon',
}: IconProps) => {
  return (
    <svg
      width={width}
      fill={fill}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
    >
      <path d="M7 13 1 7l6-6" stroke={stroke} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
