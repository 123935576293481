import { gql } from '@apollo/client'

export const Car = gql`
  fragment Car on Car {
    accessories
    ad {
      title
      description
    }
    auction {
      start
      end
    }
    brand
    createdAt
    updatedAt
    createdBy
    equipment
    isFeatured
    images {
      main
      gallery
    }
    model
    price {
      kinds {
        current
        average
        buyNow
        reserve
        starting
      }
    }
    structuralInfo {
      body {
        type
        outside_color
        inside_color
        ports
      }
      engine {
        kilometers
        traction
        kw
        horses
        cc
        cilinders
        fuel {
          combinedConsumption
          type
          emission {
            class
          }
        }
      }
      interiorType
      wheel {
        alloy
        size
      }
      gear {
        type
        shifts
      }
      weight
    }
    usedCondition {
      serviceBooklet
      registrationYear
      previousOwners
      wasSmoker
      hadPet
      privateGarage
      additionalKey
    }
    uuid
    state
  }
`
