import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { get } from 'lodash'
import { DateTime } from 'luxon'
import { Controller, FieldValues } from 'react-hook-form'
import { useThemeContext } from '../../../../providers/hooks/useThemeContext'
import { useFormContext } from '../../hooks/useFormContext'
import { InputDateProps } from './types'

const InputDate = <T extends FieldValues>({ fieldName, required, label }: InputDateProps<T>) => {
  const theme = useThemeContext()

  const {
    control,
    formState: { errors },
  } = useFormContext<T>()

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ required }}
      render={({ field: { onChange, value } }) => {
        const convertDate = (date: string | number | object) => {
          if (typeof date === 'number') return DateTime.fromSeconds(value).setZone('Europe/Rome')
          if (typeof date === 'object') return date
          return DateTime.fromISO(value).setZone('Europe/Rome')
        }

        const d = convertDate(value)

        return (
          <DateTimePicker
            value={d ? d : undefined}
            onChange={onChange}
            label={label}
            ampmInClock={false}
            format="dd/MM/yyyy - HH:mm"
            minDate={DateTime.now().setZone('Europe/Rome')} // TODO: ci serve un requisito sul quando deve partire
            minutesStep={5}
            ampm={false}
            slotProps={{
              textField: {
                InputLabelProps: {
                  required: required,
                  sx: {
                    background: theme.palette.background.paper,
                    paddingInline: '16px',
                    borderRadius: '16px',
                    color: theme.palette.primary.light,
                  },
                },
                InputProps: {
                  sx: {
                    background: theme.palette.background.paper,
                  },
                },
                error: !!get(errors, fieldName),
                sx: () => ({
                  borderRadius: '12px',
                  boxShadow: theme.shadows[4],
                  svg: {
                    fill: get(errors, fieldName) ? theme.palette.error.main : 'currentColor',
                  },
                }),
              },
            }}
          />
        )
      }}
    />
  )
}

export default InputDate
