import { TextField } from '@mui/material'
import { Controller, FieldValues } from 'react-hook-form'
import { useFormContext } from '../../hooks/useFormContext'
import { FormFieldValue } from '../types'
import { HiddenFieldProps } from './types'

const HiddenField = <T extends FieldValues>({
  fieldName,
  defaultValue = '',
  className,
}: HiddenFieldProps<T>) => {
  const context = useFormContext<T>()

  const { control } = context

  return (
    <Controller
      control={control}
      name={fieldName}
      defaultValue={defaultValue as FormFieldValue<T>}
      render={({ field: { value } }) => {
        return (
          <TextField
            sx={{ display: 'none' }}
            className={className}
            id={fieldName}
            value={defaultValue || value}
            type={'hidden'}
          />
        )
      }}
    />
  )
}

export default HiddenField
