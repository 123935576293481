import {
  FormControlLabel,
  RadioGroup as MaterialRadioGroup,
  Radio as MaterialRadioUnstyled,
  Tabs as MaterialTabs,
  ToggleButton as ToggleButtonMaterial,
} from '@mui/material'
import styled from 'styled-components'

export const ToggleButton = styled(ToggleButtonMaterial)`
  &.MuiButtonBase-root.MuiToggleButton-root {
    text-transform: Capitalize;
    min-height: unset;
    font-size: 14px;
    line-height: 1;
    padding: 6px 16px;
    border: unset;
    border-radius: 9px;

    &:hover {
      background: ${({ theme }) => theme.palette.secondary.main};
      opacity: 0.5;
    }

    &.Mui-selected {
      background: ${({ theme }) => theme.palette.secondary.main};
      &:hover {
        opacity: 1;
      }
    }
  }
`

export const Tabs = styled(MaterialTabs)`
  color: #8f8f8f;
  margin-top: 12px;
  padding: 4px;
  width: auto;
  min-height: unset !important;
  position: relative;
  text-transform: capitalize;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  border: 1px solid #bdbdbd;
`

export const RadioControlLabel = styled(FormControlLabel)``
export const RadioGroup = styled(MaterialRadioGroup)``

export const MaterialRadio = styled(MaterialRadioUnstyled)<{ hideControl?: boolean }>`
  display: ${({ hideControl }) => (hideControl ? 'none' : 'inline')};
`
