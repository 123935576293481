import { DateTime } from 'luxon'

export const minAcceptableDate = () => {
  return DateTime.now()
}

export const getFormResetOptions = () => ({
  keepErrors: false,
  keepTouched: false,
  keepIsSubmitted: false,
  keepDirty: false,
  keepValues: false,
})
