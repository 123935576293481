import { FormControlLabel } from '@mui/material'
import styled from 'styled-components'

export const RadioInput = styled.input`
  display: none;
  &:checked + label {
    scale: 1.4;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  }
`

export const Label = styled.label<{ hasErrors?: boolean }>`
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  ${({ hasErrors, theme }) => (hasErrors ? `box-shadow: ${theme.palette.error.main} 0px 0px 2px 3px` : null)}
`

export const Span = styled.span<{ background: string }>`
  position: absolute;
  top: 2.8px;
  left: 2.8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
  transition: transform 0.1s ease-in-out;
  background: ${({ background }) => background};
  border: ${({ background }) => (background === '#FFFFFF' ? '1px solid black' : 'none')};
  &:hover {
    transform: scale(1.3);
  }
`

export const ColorPickerLabel = styled(FormControlLabel)`
  align-items: start !important;
  margin: 0;
  gap: 12px;
`
