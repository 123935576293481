import { RegisterLayoutPage } from '../../pages/private/signIn'
import { SignInFormButtonContainer } from '../../pages/private/signIn/style'
import { useTranslation } from '../../providers/hooks/i18n'
import { SecondaryButton } from '../Button'
import { Title } from '../Typography'

export const ConfirmEmail = () => {
  const { t } = useTranslation()

  return (
    <RegisterLayoutPage>
      <Title color="primaryLight">{t('register.message')} </Title>
      {/* <Body color="primaryLight">{t('confirmEmail.message')}</Body> */}
      <SignInFormButtonContainer>
        <SecondaryButton color="secondary" navigateTo={'/login'}>
          {t('core.login')}
        </SecondaryButton>
      </SignInFormButtonContainer>
    </RegisterLayoutPage>
  )
}
