import { Tooltip } from '@mui/material'
import { get } from 'lodash'
import { FC } from 'react'
import { Controller, FieldValues } from 'react-hook-form'
import { useFormContext } from '../../hooks/useFormContext'
import { colorsValueHexMap } from './data'
import { ColorPickerLabel, Label, RadioInput, Span } from './style'
import { ColorPickerProps, RadioColorProps } from './types'

const RadioColor: FC<RadioColorProps> = ({ label, fieldValue, value, defaultValue, onChange, error }) => {
  const id = `${label}-${fieldValue}`
  return (
    <>
      <RadioInput
        type="radio"
        name={fieldValue}
        id={id}
        onChange={onChange}
        checked={value === defaultValue}
      />
      <Tooltip title={label} placement="bottom-start">
        <Label hasErrors={error} htmlFor={id}>
          <Span background={colorsValueHexMap[value] || '#FFFFFF'}></Span>
        </Label>
      </Tooltip>
    </>
  )
}

const ColorPicker = <T extends FieldValues>({ label, fieldName, required, options }: ColorPickerProps<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<T>()

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{ required }}
      render={({ field: { onChange, name, value } }) => {
        const RadioColors = (
          <div>
            {options.map((o, i) => {
              return (
                <RadioColor
                  error={!!get(errors, fieldName)}
                  key={i}
                  defaultValue={value}
                  fieldValue={name}
                  onChange={() => onChange(o.value)}
                  value={o.value}
                  label={o.label}
                />
              )
            })}
          </div>
        )

        return <ColorPickerLabel labelPlacement="top" label={label} control={RadioColors} />
      }}
    />
  )
}

export default ColorPicker
