import { Body } from '@core/components/Typography'
import { useTranslation } from '@core/providers/hooks/i18n'
import { usePageChecker } from '@hooks/usePageChecker'
import { Tabs as MaterialTabs } from '@mui/material'
import { includes } from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { StyledTab, StyledTabsList, TabsWrapper } from './style'
import { TabsPropsInterface, ToolbarTab } from './types'

const Tabs = ({ tabsList, ...props }: TabsPropsInterface) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const sanitizeTabPath = (path: string) => `/${path.replace('.', '/')}`
  const [activeTab, setActiveTab] = useState<string>(sanitizeTabPath(tabsList[0].path))
  const { isSellPage, isBuyPage } = usePageChecker()

  useEffect(() => {
    if (isSellPage) {
      const isAddCarForm = includes(location.pathname, sanitizeTabPath(tabsList[0].path))
      return setActiveTab(isAddCarForm ? '/sell/add' : location.pathname)
    }
    if (isBuyPage) {
      const isCarDetails = includes(location.pathname, sanitizeTabPath(tabsList[0].path))
      return setActiveTab(isCarDetails ? '/buy/overview' : location.pathname)
    }
    setActiveTab(location.pathname)
  }, [isBuyPage, isSellPage, location, tabsList])

  return (
    <TabsWrapper>
      <MaterialTabs ScrollButtonComponent={StyledTabsList} value={activeTab} indicatorColor="secondary">
        {tabsList.map((tabs: ToolbarTab, index: number) => {
          const tabPath = sanitizeTabPath(tabs.path)
          let val = tabPath
          if (isSellPage) {
            const isAddCarForm = includes('sell/add', tabPath)
            val = isAddCarForm ? '/sell/add' : tabPath
          }
          if (isBuyPage) {
            const isAddCarForm = includes('sell/add', tabPath)
            val = isAddCarForm ? '/buy/overview' : tabPath
          }
          return (
            <StyledTab
              {...props}
              disabled={!tabs.available}
              label={<Body>{t(`tabs.${tabs.path}`)}</Body>}
              onClick={() => navigate(tabPath)}
              key={`tabs-${tabPath}-${index}`}
              value={val}
            />
          )
        })}
      </MaterialTabs>
    </TabsWrapper>
  )
}
export default Tabs
