import { IconProps } from './types'

export const DeleteIcon = ({
  width = '100%',
  height = '100%',
  fill = 'currentColor',
  stroke = 'none',
  className = 'customIcon',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      className={className}
      viewBox="0 0 18 18"
    >
      <path
        fill={fill}
        d="M3.306 18c-.606 0-1.125-.196-1.557-.588S1.102 16.55 1.102 16V3H0V1h5.51V0h6.61v1h5.51v2h-1.102v13c0 .55-.216 1.02-.648 1.413-.431.391-.95.587-1.556.587zM14.324 3H3.306v13h11.018zM5.51 14h2.204V5H5.509zm4.408 0h2.203V5H9.917z"
      />
    </svg>
  )
}
