import { useInitI18n } from 'core/src/providers/hooks/i18n'
import { FC, useCallback, useMemo, useState } from 'react'
import { I18nContext } from 'react-i18next'
import { LoadingLayer } from '../components/LoadingLayer'
import { useGraphqlLazy } from '../hooks/queries/graphqlHooks'
import { TranslationFetcherProps } from './types'

export const TranslationContext = I18nContext

const TranslationProvider: FC<TranslationFetcherProps> = ({ collectionType, children }) => {
  const [readAllLabelsReq] = useGraphqlLazy('getLabels', { collectionType }, { cachePolicy: 'network-only' })
  const [hasLoadedTranslations, setHasLoadedTranslations] = useState(false)

  const readAllLabelsReqCB = useCallback(async () => {
    const res = await readAllLabelsReq()
    setHasLoadedTranslations(true)
    return res
  }, [readAllLabelsReq])

  const initI18n = useInitI18n(readAllLabelsReqCB)
  const i18nInstance = useMemo(initI18n, [initI18n])

  return (
    <TranslationContext.Provider value={{ i18n: i18nInstance }}>
      {hasLoadedTranslations ? children : <LoadingLayer />}
    </TranslationContext.Provider>
  )
}

export default TranslationProvider
