import { FormControl, FormLabel } from '@mui/material'
import { RadioProps } from 'core/src/components/Form/FormWidgets/Radio/types'
import { Controller, ControllerRenderProps, FieldValues, Path } from 'react-hook-form'
import { useFormContext } from '../../hooks/useFormContext'
import { FormFieldValue } from '../types'
import { MaterialRadio, RadioControlLabel, RadioGroup, Tabs, ToggleButton } from './styles'

const Radio = <T extends FieldValues>({
  label,
  fieldName,
  required,
  options,
  defaultValue,
  variant: variantProp,
  hideControl,
}: RadioProps<T>) => {
  const { control } = useFormContext<T>()
  const variant = variantProp ?? 'standard'

  const renderStandardRadio = ({ onChange, value }: ControllerRenderProps<T, Path<T>>) => {
    return (
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <RadioGroup name={fieldName} onChange={onChange} id={fieldName} value={value}>
          {options.map((o, i) => (
            <RadioControlLabel
              key={`${o.label}-${i}`}
              value={o.value}
              label={o.label}
              control={<MaterialRadio hideControl={hideControl} />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }

  const renderToggledRadio = ({ onChange, value }: ControllerRenderProps<T, Path<T>>) => {
    return (
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Tabs
          id={fieldName}
          value={value}
          variant="fullWidth"
          onChange={onChange}
          TabIndicatorProps={{
            sx: {
              display: 'none',
            },
          }}
        >
          {options.map((o, i) => (
            <ToggleButton selected key={`${o.label}-${i}`} value={o.value} color="primary">
              {o.label}
            </ToggleButton>
          ))}
        </Tabs>
      </FormControl>
    )
  }

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{ required }}
      defaultValue={defaultValue as FormFieldValue<T>}
      render={({ field }) =>
        variant === 'standard' ? renderStandardRadio(field) : renderToggledRadio(field)
      }
    />
  )
}

export default Radio
