import BackgroundImage from 'core/src/assets/images/user-bg.png'
import styled from 'styled-components'

export const LoginWrapper = styled.div`
  background:
    url(${BackgroundImage}) no-repeat fixed center,
    linear-gradient(180deg, #0b1728 0%, #111111 100%);
  background-size: cover;
  padding: 0 30px;
  height: 100dvh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`
export const SignupWrapper = styled(LoginWrapper)`
  background:
    url(${BackgroundImage}) no-repeat fixed center,
    linear-gradient(180deg, #1c1c1c 0%, #242424 100%);
  background-size: cover;
`

export const LoginFormContainer = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 100%;
`
export const BackToLinkStyle = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`

export const SignInFormButtonContainer = styled.div`
  button {
    transform: translateX(-50%);
    left: 50%;
  }
`
